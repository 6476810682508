import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      <header className="w-full max-w-3xl bg-white shadow-lg rounded-xl p-6 text-center">
        <h1 className="text-3xl font-bold text-blue-600">TOBO Billing App</h1>
        <p className="text-gray-500">By Ocena Smart Solutions</p>
      </header>

      <main className="w-full max-w-3xl mt-6 bg-white shadow-lg rounded-xl p-6">
        <h2 className="text-2xl font-semibold text-gray-800">Terms and Conditions About TOBO</h2>
        <p className="text-gray-600 mt-2">
          TOBO is a free billing application developed by Ocena Smart Solutions, designed to simplify business accounting and
          invoice management for Indian businesses of all sizes.
        </p>

        <div className="mt-4 space-y-6">
          <section className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-medium text-blue-600">1. Service Overview</h3>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>TOBO is provided as a free service with all features.</li>
              <li>Developed and maintained by Ocena Smart Solutions.</li>
              <li>Cloud-based service with reasonable usage limits.</li>
              <li>Designed for legitimate business purposes only.</li>
            </ul>
          </section>

          <section className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-medium text-blue-600">2. Account Registration and Usage</h3>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Users must provide accurate business and personal information.</li>
              <li>Users are responsible for maintaining account security.</li>
              <li>Ocena Smart Solutions reserves the right to suspend accounts engaged in misuse.</li>
            </ul>
          </section>

          <section className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-medium text-blue-600">3. Service Limitations</h3>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Service availability dependent on internet connectivity.</li>
              <li>Scheduled maintenance will be announced in advance.</li>
              <li>No warranty for uninterrupted or error-free operation.</li>
            </ul>
          </section>

          <section className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-medium text-blue-600">4. Intellectual Property</h3>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>All content and technology within TOBO remains the property of Ocena Smart Solutions.</li>
              <li>Reverse engineering or unauthorized modification prohibited.</li>
              <li>User-generated content remains property of the user.</li>
            </ul>
          </section>
        </div>

        <div className="mt-6 text-center">
          <p className="text-gray-600">By using TOBO, you agree to these Terms and Conditions.</p>
        </div>
      </main>

      <footer className="w-full max-w-3xl mt-6 bg-white shadow-lg rounded-xl p-4 text-center text-gray-500">
        <p>&copy; {new Date().getFullYear()} Ocena Smart Solutions. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
