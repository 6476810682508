import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Import your existing chain images
import Bitcoin from './assets/coins/Bitcoin.png';
import Binance from './assets/coins/Binance.png';
import Ethereum from './assets/coins/Ethereum.png';
import Tron from './assets/coins/Tron.png';
import Polygon from './assets/coins/Polygon.png';
import Solana from './assets/coins/Solana.png';

const ChainCarousel = () => {
  const chains = [
    { name: 'Tron', image: Tron },
    { name: 'Binance', image: Binance },
    { name: 'Polygon', image: Polygon },
    { name: 'Bitcoin', image: Bitcoin },
    { name: 'Ethereum', image: Ethereum },
    { name: 'Solana', image: Solana }
  ];

  const duplicatedChains = [...chains, ...chains];

  const [animationDuration, setAnimationDuration] = useState(18);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setAnimationDuration(5); 
    } else if (width < 1024) 
        {
        setAnimationDuration(5); 
    } else {    
        setAnimationDuration(5); 
    }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

return (
    <div className="w-full py-1 md:py-1 bg-blue-200">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">
          Chains We Work Upon
        </h2>

        <div className="relative w-full overflow-hidden">
          <div className="relative w-full h-32 sm:h-40 md:h-48">
            <motion.div
              className="absolute flex space-x-12 sm:space-x-20 md:space-x-32"
              initial={{ x: '100%' }}
              animate={{ x: '-100%' }}
              transition={{
                x: {
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: animationDuration,
                  ease: 'linear',
                },
              }}
            >
              {duplicatedChains.map((chain, index) => (
                <div
                  key={`${chain.name}-${index}`}
                  className="flex-shrink-0 transition-transform duration-300 hover:scale-110 cursor-pointer"
                >
                  <img
                    src={chain.image}
                    alt={chain.name}
                    className="w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 object-contain"
                  />
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChainCarousel;
