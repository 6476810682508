import React from "react";
import ProjectImage from './assets/office.jpg';

const ExperienceSection = () => {
  return (
    <div className="relative w-full h-auto">
      <img 
        src={ProjectImage} 
        alt="Office" 
        className="w-full h-[400px] md:h-[500px] lg:h-[630px] object-cover"
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white p-4 text-center">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 w-full text-center">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-[4rem] font-bold">70+</h2>
            <p className="text-xl md:text-2xl">Clients</p>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-[4rem] font-bold">100+</h2>
            <p className="text-xl md:text-2xl">Projects</p>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-[4rem] font-bold">500</h2>
            <p className="text-xl md:text-2xl">Hours</p>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-[4rem] font-bold">100%</h2>
            <p className="text-xl md:text-2xl">Satisfaction</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExperienceSection;