import React from 'react';

import Consulting from './assets/services/Consulting.jpg';
import Development from './assets/services/development.jpg';
import Document from './assets/services/document.jpg';
import Marketing from './assets/services/marketing.jpg';
import Security from './assets/services/security.jpg';
import Training from './assets/services/Training.jpg';

const services = [
    {
      img: Consulting,
      title: "Consulting",
      description: "Consulting where strategic insights meet cutting edge technology. Empowers organisation to navigate the complexities of decentralized system. creating value and enhancing efficiency, transforming innovative ideas into practical applications."
    },
    {
      img: Document,
      title: "Documentation",
      description: "Documentation in blockchain is essential for fostering clarity, collaboration and usability throughout the lifecycle of a project, making it easier for developers,users and stakeholders to understand and engage with the system.Our detailed documentation."
    },
    {
      img: Development,
      title: "Design and Development",
      description: "Blockchain service is built on a comprehensive design and development that ensures tailored solutions for our clients.We develop smart contracts and blockchain protocols,ensuring rigorous testing at every stage.With our expertise ,we empower businesses."
    },
    {
      img: Marketing,
      title: "Marketing and PR",
      description: "Marketing and public relation in blockchain builds awareness and trust.Strategic partnership enhances credibility and expand audience.We specialize in crafting impactful campaigns that highlight blockchain innovations.Our PR strategies focus on establishing a strong market."
    },
    {
      img: Training,
      title: "Training and Support",
      description: "Provide hands-on training and ongoing support to help teams understand and effectively use blockchain technology in their operations. Empowers businesses with knowledge and ensures a smooth adoption of blockchain solutions and fosters confidence."
    },
    {
      img: Security,
      title: "Security Audits",
      description: "Conduct thorough blockchain security audits to identify vulnerabilities in smart contracts, protocols, and systems. Ensure compliance with industry standards and protect assets from potential attacks. Builds trust with clients by ensuring their blockchain solutions ."
    }
  ];
  
  function ServiceWeOffer() {
    return (
      <div className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Services We Offer</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl border-2 border-gray-200 shadow-lg p-4 h-full group cursor-pointer transition-all duration-300 hover:border-purple-300 hover:shadow-blue-200 relative"
              >
                {/* Neon glow effect */}
                <div className="absolute -inset-0.5 bg-blue-100 opacity-0 group-hover:opacity-20 rounded-xl blur transition duration-300" />
                
                {/* Card content */}
                <div className="relative">
                  <div className="relative h-64 rounded-lg overflow-hidden">
                    <img 
                      src={service.img} 
                      alt={service.title} 
                      className="w-full h-full object-cover rounded-lg transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60 rounded-lg pointer-events-none" />
                  </div>
                  
                  <div className="p-6 bg-white">
                    <h3 className="text-2xl font-bold mb-4 text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
                      {service.title}
                    </h3>
                    <p className="text-gray-600 text-sm leading-relaxed">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  export default ServiceWeOffer;
