import React from "react";
import Link from './assets/linkchain.png';


const BenefitsPage = () => {
  const leftItems = [
    "Enhanced Security",
    "Decentralized AI Models",
    "Improved Transparency",
  ];

  const rightItems = [
    "Smart Contracts",
    "Efficient Data Processing",
    "Trustless AI Transactions",
  ];

  return (
    <div className="bg-white py-8 md:py-16">
    <div className="container mx-auto px-4">
      <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">
        Benefits Of Interlinking Blockchain with AI
      </h2>
      
      {/* Desktop Layout */}
      <div className="hidden md:flex relative justify-center items-center min-h-[600px]">
        {/* Center Image */}
        <div className="z-10 mx-auto w-48 md:w-64 h-48 md:h-64 transition-transform hover:scale-105">
          <img 
            src={Link}
            alt="Chain Link" 
            className="w-full h-full object-contain"
          />
        </div>

        {/* Connection Lines - Only visible on desktop */}
        <div className="absolute bg-blue-400" style={{ width: '240px', height: '2px', left: '300px', top: '218px', transform: 'rotate(16deg)', transformOrigin: 'right center' }} />
        <div className="absolute bg-blue-400" style={{ width: '280px', height: '2px', left: '300px', top: '300px' }} />
        <div className="absolute bg-blue-400" style={{ width: '267px', height: '2px', left: '85px', top: '481px', transform: 'rotate(160deg)', transformOrigin: 'right center' }} />
        <div className="absolute bg-blue-400" style={{ width: '261px', height: '2px', right: '65px', top: '118px', transform: 'rotate(-199deg)', transformOrigin: 'left center' }} />
        <div className="absolute bg-blue-400" style={{ width: '256px', height: '2px', right: '300px', top: '300px' }} />
        <div className="absolute bg-blue-400" style={{ width: '223px', height: '2px', right: '106px', top: '472px', transform: 'rotate(-163deg)', transformOrigin: 'left center' }} />

        {/* Desktop Side Items */}
        <div className="absolute left-0 flex flex-col justify-evenly h-full space-y-4">
          {leftItems.map((text, index) => (
            <div key={index} className="flex items-center mb-4">
              <div className="p-8 bg-blue-200 text-center rounded-lg hover:-translate-y-2 transition-transform text-lg">
                {text}
              </div>
            </div>
          ))}
        </div>

        <div className="absolute right-0 flex flex-col justify-evenly h-full space-y-4">
          {rightItems.map((text, index) => (
            <div key={index} className="flex items-center mb-4">
              <div className="p-8 bg-blue-200 text-center rounded-lg hover:-translate-y-2 transition-transform text-lg">
                {text}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="md:hidden relative min-h-[335px] flex justify-center items-center">
        {/* Center Image */}
        <div className="absolute z-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-24 h-24">
        <div className="w-full h-full rounded-full border-2 border-purple-500 flex items-center justify-center">
          <img 
            src={Link}
            alt="Chain Link" 
            className="w-full h-full object-contain"
          />
          </div>
        </div>

        {/* Connection Lines for Mobile */}
        <div className="absolute bg-blue-400"
         style={{   
           width: '7vh',
          height: '2px',
          left: '202px',
          top: '22%',
          transform: 'rotate(-44deg)',
          transformOrigin: 'right center'
        }}
          />
        <div className="absolute bg-blue-400" style={{ width: '1vh', height: '2px', left: '140px', top: '50%' }} />
        <div className="absolute bg-blue-400" style={{ width: '7vh', height: '2px', right: '129px', top: '74%', transform: 'rotate(27deg)', transformOrigin: 'right center' }} />
        <div className="absolute bg-blue-400" style={{ width: '6vh', height: '2px', right: '213px', top: '25%', transform: 'rotate(-310deg)', transformOrigin: 'left center' }} />
        <div className="absolute bg-blue-400" style={{ width: '1vh', height: '2px', right: '140px', top: '50%' }} />
        <div className="absolute bg-blue-400" style={{ width: '6vh', height: '2px', right: '214px', top: '74%', transform: 'rotate(-34deg)', transformOrigin: 'left center' }} />

        {/* Left Items */}
        <div className="absolute left-0 flex flex-col justify-evenly h-full py-8">
          {leftItems.map((text, index) => (
            <div key={index} className="flex items-center">
<div className="p-3 bg-blue-200 text-center rounded-lg text-xs w-32">
{text}
              </div>
            </div>
          ))}
        </div>

        {/* Right Items */}
        <div className="absolute right-0 flex flex-col justify-evenly h-full py-8">
          {rightItems.map((text, index) => (
            <div key={index} className="flex items-center">
<div className="p-3 bg-blue-200 text-center rounded-lg text-xs w-32">
{text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default BenefitsPage;
