import React from 'react';
import { MonitorSmartphone, BarChart3 } from 'lucide-react';
import WhyChooseUs from './Whychoseus';
import Contact from '../Contact/Contact';
import ServiceWeoffer from './ServiceWeoffer';
import ChainCarousel from './ChainCarousel';
import BenefitsPage from './BenefitsPage';
import TechStack from './TechStack';
import HeroSection from './HeroSection';
import ExperienceSection from './ExperienceSection';



import trusted1 from './assets/Trusted_innovators/trusted1.jpg'
import trusted2 from './assets/Trusted_innovators/trusted2.jpg'
import trusted3 from './assets/Trusted_innovators/trusted3.jpg'
import trusted4 from './assets/Trusted_innovators/trusted4.jpg'

import smallCard1 from './assets/small_cards/bg1.jpg';
import smallCard2 from './assets/small_cards/bg2.jpg';
import smallCard3 from './assets/small_cards/bg3.jpg';
import smallCard4 from './assets/small_cards/bg4.jpg';
import smallCard5 from './assets/small_cards/bg5.jpg'


import t1 from './assets/Testimonials/wazwan.png'
import t2 from './assets/Testimonials/uniportal.png'
import t3 from './assets/Testimonials/james.png'


import NewsletterPage from './Newsletter';

const Home = () => {
 
  const metrics = [
    { title: "Boosted Efficiency", value: "40%", icon: smallCard1 },
    { title: "Client Satisfaction", value: "90%", icon: smallCard2 },
    { title: "Smart Contracts Deployed", value: "50+", icon: smallCard3 },
  ];

  const secondaryMetrics = [
    { title: "Transaction Speed", value: "2x", icon: smallCard4 },
    { title: "Network Scalability", value: "1x", icon: smallCard5 },
  ];

  return (
  <div>

<div>
<HeroSection />
</div>

<div>
<ServiceWeoffer />
</div>

<BenefitsPage />

<div>
  <ChainCarousel />
</div>

{/* Trusted Innovators Section */}
<div>
  <h2 className="text-3xl font-bold mb-8 text-center">Trusted Innovator In Advancing Blockchain Technology Solutions</h2>
  <h1 className="text-lg mb-8 text-center">Driving Transformative blockchain solutions with innovations and trust at the core</h1>
  
  {/* New Card Section */}
  <div className="container mx-auto px-4 py-12">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Card 1 */}
      <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 group">
        <img 
          src={trusted1} 
          alt="Blockchain Network" 
          className="w-90 h-72 object-none"
        />
        <div className="absolute inset-0 bg-white bg-opacity-90 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 flex items-center justify-center p-6">
          <div className="text-center">
            <h3 className="text-xl font-bold text-black mb-4">Innovative Network Solutions</h3>
            <p className="text-gray-600">Advanced blockchain network architecture ensuring maximum security and scalability for enterprise solutions.</p>
          </div>
        </div>
      </div>

      {/* Card 2 */}
      <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 group">
        <img 
          src={trusted2} 
          alt="AI Integration" 
          className="w-90 h-72 object-cover"
        />
        <div className="absolute inset-0 bg-white bg-opacity-90 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 flex items-center justify-center p-6">
          <div className="text-center">
            <h3 className="text-xl font-bold text-black mb-4">AI-Powered Analytics</h3>
            <p className="text-gray-600">Cutting-edge AI integration for real-time blockchain data analysis and predictive insights.</p>
          </div>
        </div>
      </div>

      {/* Card 3 */}
      <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 group">
        <img 
          src={trusted3} 
          alt="Global Network" 
          className="w-full h-72 object-cover"
        />
        <div className="absolute inset-0 bg-white bg-opacity-90 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 flex items-center justify-center p-6">
          <div className="text-center">
            <h3 className="text-xl font-bold text-black mb-4">Global Connectivity</h3>
            <p className="text-gray-600">Worldwide blockchain network infrastructure enabling seamless cross-border transactions.</p>
          </div>
        </div>
      </div>

      {/* Card 4 */}
      <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 group">
        <img 
          src={trusted4} 
          alt="Enterprise Solutions" 
          className="w-full h-72 object-cover"
        />
        <div className="absolute inset-0 bg-white bg-opacity-90 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 flex items-center justify-center p-6">
          <div className="text-center">
            <h3 className="text-xl font-bold text-black mb-4">Enterprise Solutions</h3>
            <p className="text-gray-600">Customized blockchain solutions designed specifically for enterprise-level requirements.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* Small Card Section */}
<div className="bg-gradient-to-br from-pink-100 to-purple-200 py-16 px-4">
  <div className="container mx-auto">
    <h2 className="text-3xl font-bold mb-12 text-center text-gray-800">
      Key Metrics
    </h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
      {metrics.map((metric, index) => (
        <div
          key={index}
          className="bg-gradient-to-b from-purple-200 to-purple-300 backdrop-blur-sm rounded-3xl p-8 min-h-80 text-center transform hover:scale-105 transition-transform duration-300 shadow-lg"
        >
          <div className="w-40 mx-auto mb-6 relative">
            <div className="absolute inset-x-0 top-0 h-12 bg-gradient-to-b from-white/70 to-transparent z-10 rounded-t-3xl"></div>
            <img
              src={metric.icon}
              alt={metric.title}
              className="w-full h-48 object-contain relative z-0"
            />
          </div>
          <h3 className="text-gray-700 font-medium mb-4">{metric.title}</h3>
          <p className="text-4xl font-bold text-gray-900">{metric.value}</p>
        </div>
      ))}
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-4xl mx-auto mt-8">
      {secondaryMetrics.map((metric, index) => (
        <div
          key={index}
          className="bg-gradient-to-b from-purple-200 to-purple-300 backdrop-blur-sm rounded-3xl p-8 min-h-80 text-center transform hover:scale-105 transition-transform duration-300 shadow-lg"
        >
          <div className="w-40 mx-auto mb-6 relative">
            <div className="absolute inset-x-0 top-0 h-12 bg-gradient-to-b from-white/70 to-transparent z-10 rounded-t-3xl"></div>
            <img
              src={metric.icon}
              alt={metric.title}
              className="w-full h-48 object-contain relative z-0"
            />
          </div>
          <h3 className="text-gray-700 font-medium mb-4">{metric.title}</h3>
          <p className="text-4xl font-bold text-gray-900">{metric.value}</p>
        </div>
      ))}
    </div>
  </div>
</div>



<TechStack />

    <div className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-bold text-center mb-4">Discovering Our Goal-Driven Strategy</h2>
        <p className="text-gray-600 text-center mb-16">Our carefully crafted plan designed to ensure your business thrives.</p>
        
        <div className="relative space-y-32">
          {/* Sections */}
          <div className="flex items-center">
            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
              <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#2563EB" strokeWidth="2"/>
                <path d="M21 21L16.65 16.65" stroke="#2563EB" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </div>
            <div className="ml-8 flex-1">
              <div className="border-t-2 border-blue-200 pt-4">
                <h3 className="text-xl font-semibold mb-4">Discovery and Analysis</h3>
                <ul className="space-y-2">
                  <li>→ Conduct a thorough consultation to gather insights.</li>
                  <li>→ Perform a competitive analysis and market research.</li>
                  <li>→ Identify key performance indicators (KPIs) and success metrics.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse items-center">
            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
              <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                <rect x="3" y="4" width="18" height="18" rx="2" stroke="#2563EB" strokeWidth="2"/>
                <path d="M16 2V6M8 2V6M3 10H21" stroke="#2563EB" strokeWidth="2"/>
              </svg>
            </div>
            <div className="mr-8 flex-1 text-right">
              <div className="border-t-2 border-blue-200 pt-4">
                <h3 className="text-xl font-semibold mb-4">Strategic Planning</h3>
                <ul className="space-y-2">
                  <li>Outline short-term and long-term goals. ←</li>
                  <li>Create a detailed action plan with timelines. ←</li>
                  <li>Allocate resources and define roles and responsibilities. ←</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
              <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                <path d="M16 18L22 12L16 6" stroke="#2563EB" strokeWidth="2"/>
                <path d="M8 6L2 12L8 18" stroke="#2563EB" strokeWidth="2"/>
              </svg>
            </div>
            <div className="ml-8 flex-1">
              <div className="border-t-2 border-blue-200 pt-4">
                <h3 className="text-xl font-semibold mb-4">Design and Development</h3>
                <ul className="space-y-2">
                  <li>→ Develop prototypes, wireframes, or concepts.</li>
                  <li>→ Build products, services, or solutions based on the plan.</li>
                  <li>→ Implement feedback through iterative guidelines.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse items-center">
            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
              <MonitorSmartphone className="w-8 h-8 text-blue-600" />
            </div>
            <div className="mr-8 flex-1 text-right">
              <div className="border-t-2 border-blue-200 pt-4">
                <h3 className="text-xl font-semibold mb-4">Implementation</h3>
                <ul className="space-y-2">
                  <li>Deploy solutions across target platforms. ←</li>
                  <li>Execute the planned strategies systematically. ←</li>
                  <li>Ensure seamless integration with existing systems. ←</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
              <BarChart3 className="w-8 h-8 text-blue-600" />
            </div>
            <div className="ml-8 flex-1">
              <div className="border-t-2 border-blue-200 pt-4">
                <h3 className="text-xl font-semibold mb-4">Performance Monitoring</h3>
                <ul className="space-y-2">
                  <li>→ Track key performance metrics and analytics.</li>
                  <li>→ Generate comprehensive performance reports.</li>
                  <li>→ Implement continuous improvement strategies.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

 {/* Testimonials Section */}
 <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-4">Testimonials</h2>
          <p className="text-gray-600 text-center mb-12">Hear from those who trusted us</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                name: "Wazwan Restaurants",
                role: "Luxury Food Chain",
                image: t1,
                text: "Ocena designed an efficient restaurant management system, simplifying our daily tasks and boosting productivity effortlessly."
              },
              {
                name: "UniPortal",
                role: "Educational Consultancy",
                image: t2,
                text: "They developed a well-structured educational portal, enhancing accessibility and making online learning more effective."
              },
              {
                name: "James Blue",
                role: "CEO at Blockgames",
                image: t3,
                text: "Ocena optimized my smart contract, reducing gas fees and improving efficiency without compromising security."
              }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white rounded-lg p-8 shadow-lg">
                <div className="flex flex-col items-center">
                  <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
                    <img src={testimonial.image} alt={testimonial.name} className="w-full h-full object-cover" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{testimonial.name}</h3>
                  <p className="text-gray-600 mb-4">{testimonial.role}</p>
                  <p className="text-center mb-4">{testimonial.text}</p>
                  <div className="flex space-x-1">
                    {[...Array(5)].map((_, i) => (
                      <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

<ExperienceSection />

<WhyChooseUs />

<NewsletterPage />

<Contact />

    </div>
  );
};

export default Home;
