import React from 'react';
import { Instagram, Facebook, Linkedin,Twitter } from 'lucide-react';
import Logo from './assets/logo.png';
const Footer = () => {
  return (
<div className="w-full bg-[rgba(76,122,200,255)] text-white">
{/* Main Footer Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and Description Section */}
          <div className="col-span-1">
            <div className="flex items-center gap-2 mb-4">
            <img src={Logo} alt="OCENA Logo" className="h-24 w-24" />
              <div>
                <h2 className="text-2xl font-bold">OCENA</h2>
                <p className="text-sm">smart solutions</p>
              </div>
            </div>
            <p className="text-sm mb-6">
              Ocena Smart Solution empowers businesses with secure, innovative blockchain technology.
            </p>
            <div className="flex gap-4">
              <a href="https://instagram.com/ocenasmartsolutions" aria-label="Follow us on Instagram" className="p-2 bg-blue-500 rounded-lg hover:bg-blue-400">
                <Instagram size={20} />
              </a>
              <a href="https://www.facebook.com/share/19wct57rTp/" aria-label="Follow us on Facebook" className="p-2 bg-blue-500 rounded-lg hover:bg-blue-400">
                <Facebook size={20} />
              </a>
              <a href="https://in.linkedin.com/company/ocena-smart-solutions" aria-label="Connect with us on Linkedin" className="p-2 bg-blue-500 rounded-lg hover:bg-blue-400">
                <Linkedin size={20} />
              </a>
              <a href="https://x.com/Ocenasolutions" aria-label="Follow us on Twitter" className="p-2 bg-blue-500 rounded-lg hover:bg-blue-400">
                <Twitter size={20} />
              </a>
            </div>
          </div>

          {/* Product Section */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">About</h3>
            <ul className="space-y-2">
              
              <li><a href="/about" className="hover:text-blue-200">About ocena</a></li>
              <li><a href="/services" className="hover:text-blue-200">Services</a></li>
              <li><a href="/courses" className="hover:text-blue-200"> Professional Trainings</a></li>
            </ul>
          </div>

          {/* Discover More Section */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">DISCOVER MORE</h3>
            <ul className="space-y-2">
              <li><a href="/portfolio" className="hover:text-blue-200">Portfolio</a></li>
              <li><a href="/contact" className="hover:text-blue-200">Contact Us</a></li>
              <li><a href="https://calendly.com/ocenasmartsolutions/15" className="hover:text-blue-200">Book a meeting</a></li>
            
            </ul>
          </div>

          {/* Contact Section */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-start gap-2">
                <svg className="w-5 h-5 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span>Plot No. 3-A , Amari greens, Mohali, Punjab, India - 140301</span>
              </li>
              <li className="flex items-center gap-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <a href="tel:+91-7652992906" className="hover:text-blue-200">+91 7652992906</a>
              </li>
              <li className="flex items-center gap-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <a href="mailto:Hr@ocena.in" className="hover:text-blue-200">Hr@ocena.in</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="w-full bg-gradient-to-r from-blue-400 to-blue-600">
        <div className="container mx-auto px-4 py-4 text-center">
          <p> 2023 Ocena Smart Solutions. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
