import React from 'react';
import wallet from './assets/wallet.png';
import NFT from './assets/nft.png';
import Metaverse from './assets/metaverse.png';
import innovation from './assets/innovation.png';
import document from './assets/document.png';

import bot from './assets/bot.png';
import dm from './assets/dm.png';
import computer from './assets/computer.png';

import chain from './assets/developer.png';
import card from './assets/card.png';

import Secure from './assets/icons/Secure system.png';
import Support from './assets/icons/Online Support.png';
import Handshake from './assets/icons/always reliable.png';

const Services = () => {
  const handleBookMeeting = () => {
    window.open('https://doodle.com/bp/ocenasmartsolutions/meeting', '_blank');
  };

  const features = [
    {
      Mainicon: <img src={Secure} alt="Secure Icon" className="w-20 h-20" />,
      title: "Secure System",
      description: "We prioritize your security with cutting-edge technology, ensuring that your data and transactions remain protected at all times."
    },
    {
      Mainicon: <img src={Support} alt="Support Icon" className="w-20 h-20" />,
      title: "24/7 Support",
      description: "Our dedicated support team is available around the clock, ready to assist you whenever you need help, no matter the time."
    },
    {
      Mainicon: <img src={Handshake} alt="Handshake Icon" className="w-20 h-20" />,
      title: "Always Reliable",
      description: "Trust in our solutions for consistent and dependable performance. We are committed to delivering reliable results."
    }
  ];
  
  const services = [
    {
      icon: <img src={computer} alt="Document Icon" className="w-8 h-8" />,
      title: "Full Stack Development",
      description: "Offering full stack development as a service ensures end-to-end solutions, combining front-end (UI/UX), back-end (server, databases), and API integration. Benefits include cost efficiency, faster deployment, and scalable, customized applications tailored to business needs."
    },
    {
      icon: <img src={bot} alt="Bot Icon" className="w-8 h-8" />,
      title: "AI Agent",
      description: "AI agents automate tasks, enhance decision-making, and deliver personalized experiences through chatbots, predictive analytics, and process automation. They provide 24/7 efficiency, data-driven insights, and intelligent automation for competitive advantage."
    },
    {
      icon: <img src={dm} alt="Document Icon" className="w-8 h-8" />,
      title: "Web/App Development",
      description: "Web and app development services create responsive, user-friendly platforms, including e-commerce solutions, mobile apps, and PWAs. Benefits include cross-platform compatibility, enhanced user engagement, and custom features for business growth."
    },
    {
      icon: <img src={document} alt="Document Icon" className="w-8 h-8" />,
      title: "Customized Smart Contracts",
      description: "Offering customized smart contracts as a service enhances business efficiency through automated, secure, and transparent processes. This brings cost savings, accuracy, and global accessibility."
    },
    {
      icon: <img src={wallet} alt="Wallet Icon" className="w-8 h-8" />,
      title: "Wallet Development",
      description: "Decentralized wallets enable instant, global, and cost-effective transactions, fostering international business expansion. By minimizing delays and facilitating large transactions in seconds."
    },
    {
      icon: "💻",
      title: "DeFi 2.0 Development",
      description: "Transforming your ideas into reality, our dApp developers seamlessly integrate decentralized solutions into your existing infrastructure. Optimize resources, delivering cost-effective solutions."
    },
    {
      icon: <img src={innovation} alt="Innovation Icon" className="w-8 h-8" />,
      title: "Dapp Development",
      description: "Transforming your ideas into reality, our dApp developers seamlessly integrate decentralized solutions into your existing infrastructure. Optimize resources, delivering cost-effective solutions tailored."
    },
    {
      icon: <img src={NFT} alt="NFT Icon" className="w-8 h-8" />,
      title: "NFT Marketplace Development",
      description: "Harness the power of NFTs for business growth-enabling real-time asset trading, automating royalty distribution, establishes digital asset authenticity, fostering innovation, and cultivating a thriving ecosystem."
    },
    {
      icon: <img src={Metaverse} alt="Metaverse Icon" className="w-8 h-8" />,
      title: "Metaverse Development",
      description: "Unlock new dimensions in commerce, communication, and recreation through Metaverse development. Users can seamlessly explore and connect in virtual realms."
    }
  ];

  return (
    <div>
      {/* Vision Section - Responsive text sizing */}
      <div className="max-w-4xl mx-auto p-6   text-center mb-20 px-4">
        <h1 className="text-3xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
          Our Vision Is To Create A Profitable Blockchain Environment.
        </h1>
        <p className="text-gray-600 max-w-2xl mx-auto text-sm md:text-base">
          Our vision is to build a thriving and sustainable blockchain ecosystem, where innovation and profitability go hand in hand. We aim to create a dynamic environment that empowers businesses and individuals to harness the full potential of blockchain technology.
        </p>
      </div>

      {/* Features Section - Responsive grid and text */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto mb-20 px-4">
        {features.map((feature, index) => (
          <div key={index} className="text-center p-4 md:p-6 flex flex-col items-center justify-center">
            <div className="flex items-center justify-center text-3xl md:text-4xl mb-4 text-purple-600">
              {feature.Mainicon}
            </div>
            <h3 className="text-lg md:text-xl font-semibold mb-2">{feature.title}</h3>
            <p className="text-gray-600 text-sm md:text-base">{feature.description}</p>
          </div>
        ))}
      </div>

      {/* Services Section - Responsive grid and text */}
      <div className="max-w-6xl mx-auto mb-20 px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12">Blockchain Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="relative overflow-hidden bg-white rounded-lg p-4 md:p-6 shadow-lg group cursor-pointer
                        transition-all duration-500 ease-in-out
                        before:absolute before:inset-0 before:bg-gradient-to-br 
                        before:from-blue-100 before:to-purple-100 before:opacity-0 
                        hover:before:opacity-100 before:transition-opacity before:-z-10
                        hover:-translate-y-2 hover:shadow-xl"
            >
              <div className="relative z-10">
                <div className="text-2xl md:text-3xl mb-3 md:mb-4 text-purple-600 group-hover:scale-110 transition-transform duration-500">
                  {service.icon}
                </div>
                <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-3 group-hover:text-purple-700 transition-colors duration-300">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-3 md:mb-4 text-sm md:text-base group-hover:text-gray-700 transition-colors duration-300">
                  {service.description}
                </p>
                <div className="flex items-center text-purple-600 text-sm md:text-base group-hover:text-purple-700 transition-colors duration-300">
                 
                  <span className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300"></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Team Section - Responsive layout and text */}
      <div className="w-full bg-black text-white py-8 md:py-12"> 
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-4">
          <div className="w-full md:w-full mb-6 md:mb-0 bg-black text-center md:text-left">
            <img src={card} alt="Team Collaboration" className="w-full h-auto max-w-[200px] md:max-w-[300px] mx-auto md:mx-0" />
          </div>
          <div className="w-full md:w-1/2 md:pl-12 text-center md:text-left">
            <h3 className="text-lg md:text-xl text-purple-600 mb-2">Join Us</h3>
            <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Join Our Team</h2>
            <p className="text-gray-300 mb-4 md:mb-6 text-sm md:text-base">
              Our team thrives on collaboration, creativity, and inclusivity. Whether you're brainstorming innovative solutions or celebrating achievements, you'll always feel valued and supported.
            </p>
            <button className="bg-purple-600 text-white px-4 md:px-6 py-2 rounded-lg hover:bg-purple-700 transition-colors text-sm md:text-base">
              Explore More
            </button>
          </div>
        </div>
      </div>

     {/* CTA Section - Responsive layout and text */}
      <div className="w-full flex justify-center mt-12 md:mt-20 px-4">
        <div className="flex flex-col md:flex-row items-center justify-center max-w-6xl w-full">
          {/* Image div - will be first on mobile, side-by-side on desktop */}
          <div className="w-full md:w-1/3 flex justify-center mb-6 md:mb-0">
            <img src={chain} alt="Blockchain Network" className="w-full h-auto max-w-[200px] md:max-w-[250px]" />
          </div>
          
          {/* Text and button div */}
          <div className="w-full md:w-2/3 md:pl-12 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">
              Get The Best Development Services from Experts
            </h2>
            <button 
              onClick={handleBookMeeting}
              className="bg-blue-600 text-white px-6 md:px-8 py-2 md:py-3 rounded-lg hover:bg-blue-700 transition-colors text-xl md:text-3xl"
            >
              Book a meeting now
            </button>
          </div>
        </div>
      </div>

      <div className="p-6">

      </div>
    </div>
  );
};

export default Services;
