import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';
import Courses from './pages/courses/courses';
import FrontendInfo from './pages/courses/coursepage/frontend';
import BackendInfo from './pages/courses/coursepage/backend';
import FullstackInfo from './pages/courses/coursepage/fullstack';
import BlockchainInfo from './pages/courses/coursepage/blockchain';
import Privacy from './pages/privacy/privacy';
import TermsAndConditions from './pages/terms/TermsAndConditions';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import PanelLayout from './components/Layouts/PanelLayout';
import NotFound from './pages/pagenotfound/NotFound'; 

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/terms&condition" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />

        
        <Route
          path="/*"
          element={
            <PanelLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/frontend" element={<FrontendInfo />} />
                <Route path="/courses/backend" element={<BackendInfo />} />
                <Route path="/courses/fullstack" element={<FullstackInfo />} />
                <Route path="/courses/blockchain" element={<BlockchainInfo />} />
                <Route path="/services" element={<Services />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy" element={<Privacy />} />
                
                {/* Catch-all route for unknown pages */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PanelLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
