const PrivacyPolicy = () => {
    const currentYear = new Date().getFullYear()
  
    const policyData = [
      {
        title: "1. Information Collection",
        items: [
          "Business information (name, address, GST number)",
          "Contact information (email, phone number)",
          "Transaction data (invoices, payments, customer details)",
          "Usage analytics (features used, session duration)",
          "Device information (operating system, app version)",
        ],
      },
      {
        title: "2. Use of Information",
        items: [
          "To provide billing and invoicing services",
          "To improve application functionality",
          "For customer support and issue resolution",
          "To send important service announcements",
        ],
      },
      {
        title: "3. Data Storage and Security",
        items: [
          "Data primarily stored locally on user device",
          "Cloud with user permission",
          "Industry-standard encryption for all data transfers",
          "Regular security audits and vulnerability testing",
          "Access controls limiting employee access to user data",
        ],
      },
      {
        title: "4. Data Sharing",
        items: [
          "No sharing of user data with third-party services",
          "No selling of personal or business information",
          "Information only shared when legally required",
          "Anonymized aggregated data may be used for analytics",
        ],
      },
      {
        title: "5. User Rights",
        items: [
          "Right to access all stored personal and business data",
          "Right to correction of inaccurate information",
          "Right to data export in standard formats",
          "Right to account deletion and data removal",
        ],
      },
      {
        title: "6. Data Retention",
        items: [
          "Data retained as long as account remains active",
          "Inactive accounts preserved for 12 months",
          "Data deletion upon account termination request",
        ],
      },
      {
        title: "7. Security Practices",
        items: [
          "End-to-end encryption for all sensitive data",
          "Security patches and updates",
          "Secure cloud infrastructure with redundancy",
          "Automatic session timeout after inactivity",
        ],
      },
      {
        title: "8. Changes to Policy",
        items: ["Major changes communicated via registered email", "Continued use after changes constitutes acceptance"],
      },
    ]
  
    return (
      <div className="min-h-screen bg-gray-50">
        {/* Header */}
        <header className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-8 shadow-md">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold">TOBO Billing App</h1>
            <p className="text-blue-100 mt-2">By Ocena Smart Solutions</p>
          </div>
        </header>
  
        {/* Main Content */}
        <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl mx-auto">
            {/* Heading Section */}
            <div className="bg-gray-50 p-6 border-b border-gray-200">
              <h2 className="text-2xl font-bold text-gray-800">Privacy Policy</h2>
              <p className="text-gray-600 mt-2">
                This privacy policy describes how TOBO collects, uses, and protects your information when you use our
                billing application.
              </p>
            </div>
  
            {/* Policy Sections */}
            <div className="p-6">
              <div className="space-y-8">
                {policyData.map((section, index) => (
                  <section key={index} className="policy-section">
                    <h3 className="text-xl font-semibold text-gray-800 mb-3">{section.title}</h3>
                    <ul className="list-disc pl-5 space-y-2 text-gray-700">
                      {section.items.map((item, idx) => (
                        <li key={idx} className="leading-relaxed">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
  
                {/* Contact Section */}
                <section className="policy-section">
                  <h3 className="text-xl font-semibold text-gray-800 mb-3">9. Contact Information</h3>
                  <div className="bg-gray-50 p-4 rounded-md border border-gray-200">
                    <p className="text-gray-700">
                      For questions regarding this policy, please contact:{" "}
                      <a href="mailto:Business@ocena.in" className="text-blue-600 hover:underline">
                        Business@ocena.in
                      </a>
                    </p>
                    <div className="mt-4 text-gray-700">
                      <p>Ocena Smart Solutions Pvt. Ltd.</p>
                      <p>Mohali, Punjab India</p>
                      <p>+91 7876249906</p>
                    </div>
                  </div>
                </section>
              </div>
  
              {/* Acceptance Section */}
              <div className="mt-10 pt-6 border-t border-gray-200">
                <p className="text-center text-gray-700 font-medium">By using TOBO, you agree to this Privacy Policy.</p>
              </div>
            </div>
          </div>
        </main>
  
        {/* Footer */}
        <footer className="bg-gray-800 text-gray-300 py-6 mt-10">
          <div className="container mx-auto px-4 text-center">
            <p>&copy; {currentYear} Ocena Smart Solutions. All rights reserved.</p>
          </div>
        </footer>
      </div>
    )
  }
  
  export default PrivacyPolicy
  
  