import React, { useState } from 'react';
import axios from 'axios';
import Letter from './assets/letter.jpg';

const NewsletterPage = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setStatus({ type: 'error', message: 'Please enter a valid email.' });
      return;
    }

    setLoading(true);
    setStatus(null);

    try {
      const response = await axios.post('https://ocena-backend2.onrender.com/contact-email', { 
        email,
        createdAt: new Date()
      });

      console.log("API Response:", response.data); // Debugging log

      if (response.status === 200 || response.status === 201) {
        setStatus({ type: 'success', message: 'Subscription successful!' });
        setEmail('');
      }
    } catch (error) {
      console.error("API Error:", error.response || error);

      if (error.response?.status === 409) {
        setStatus({ type: 'warning', message: 'You are already subscribed.' });
      } else {
        setStatus({ type: 'error', message: 'Something went wrong. Try again later.' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="w-[90%] md:w-[80%] mx-auto bg-cover bg-center py-16 px-6 sm:px-10 text-center text-white relative overflow-hidden rounded-3xl"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${Letter})`,
        borderRadius: "50px",
      }}
    >
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-70 rounded-3xl"></div>
      
      <div className="relative z-10 max-w-5xl mx-auto">
        <h3 className="text-xl sm:text-2xl font-semibold">Newsletter</h3>
        <h2 className="text-3xl sm:text-5xl font-bold text-blue-400 mt-4">
          Subscribe to Our Newsletter
        </h2>
        <p className="text-base sm:text-lg text-white/90 mt-4 sm:mt-6 max-w-3xl mx-auto">
          Stay updated with the latest news, product launches, and exclusive promotions. Subscribe today and never miss an update!
        </p>

        {/* Form */}
        <form 
          className="flex flex-col sm:flex-row justify-center items-center mt-6 sm:mt-8 gap-4 sm:gap-6"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full sm:w-96 p-4 sm:p-5 text-base sm:text-lg rounded-full bg-gradient-to-r from-blue-300 to-blue-900 text-white placeholder-white outline-none"
            required
          />
          <button 
            type="submit"
            className="w-full sm:w-auto px-8 py-4 sm:px-10 sm:py-5 text-base sm:text-lg bg-white text-black rounded-full border border-blue-400 hover:bg-gray-200 font-bold"
            disabled={loading}
          >
            {loading ? 'SUBMITTING...' : 'SUBSCRIBE'}
          </button>
        </form>

        {/* Status messages */}
        {status && (
          <p className={`mt-4 ${status.type === 'success' ? 'text-green-400' : status.type === 'warning' ? 'text-yellow-400' : 'text-red-400'}`}>
            {status.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default NewsletterPage;
