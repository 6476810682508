import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Import your images
import HTML from './assets/technologies/HTML.png';
import solidity from './assets/technologies/solidity.png';
import Node from './assets/technologies/Node.png';
import Next from './assets/technologies/Next.png';
import CSS from './assets/technologies/Css.png';
import ReactImage from './assets/technologies/React.png';

const TechStack = () => {
  // Technologies data
  const technologies = [
    { name: 'HTML', image: HTML },
    { name: 'solidity', image: solidity },
    { name: 'Node', image: Node },
    { name: 'Next', image: Next },
    { name: 'CSS', image: CSS },
    { name: 'React', image: ReactImage }
  ];

  // Duplicate the array to create a seamless loop
  const duplicatedTechnologies = [...technologies, ...technologies];

  // Adjust animation duration based on screen width
  const [animationDuration, setAnimationDuration] = useState(18); 

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) { 
        setAnimationDuration(5); 
      } else if (width < 1024) { 
        setAnimationDuration(5); 
      } else { 
        setAnimationDuration(5);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="w-full py-8 md:py-12 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">
          Technologies We Work Upon
        </h2>

        <div className="relative w-full overflow-hidden">
          {/* Main container for the scrolling content */}
          <div className="relative w-full h-32 sm:h-40 md:h-48">
            {/* Scrolling wrapper */}
            <motion.div
              className="absolute flex space-x-12 sm:space-x-20 md:space-x-32"
              initial={{ x: '100%' }}
              animate={{ x: '-100%' }}
              transition={{
                x: {
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: animationDuration, 
                  ease: 'linear',
                },
              }}
            >
              {duplicatedTechnologies.map((tech, index) => (
                <div
                  key={`${tech.name}-${index}`}
                  className="flex-shrink-0 transition-transform duration-300 hover:scale-110 cursor-pointer"
                >
                  <img
                    src={tech.image}
                    alt={tech.name}
                    className="w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 object-contain"
                  />
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStack;
