import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Code, Server, Layers, Database } from 'lucide-react';

const Courses = () => {
  const navigate = useNavigate();

  const courses = [
    {
      title: 'Frontend Development',
      description: 'Master modern frontend technologies including HTML, CSS, JavaScript, React, and more.',
      icon: <Code className="w-12 h-12 text-blue-500" />,
      path: '/courses/frontend'
    },
    {
      title: 'Backend Development',
      description: 'Learn server-side programming with Node.js, Python, databases, and API development.',
      icon: <Server className="w-12 h-12 text-green-500" />,
      path: '/courses/backend'
    },
    {
      title: 'Full Stack Development',
      description: 'Become a complete developer by mastering both frontend and backend technologies.',
      icon: <Layers className="w-12 h-12 text-purple-500" />,
      path: '/courses/fullstack'
    },
    {
      title: 'Blockchain Development',
      description: 'Explore blockchain technology, smart contracts, and decentralized applications.',
      icon: <Database className="w-12 h-12 text-orange-500" />,
      path: '/courses/blockchain'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Our Courses</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {courses.map((course) => (
          <div 
            key={course.title}
            className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer p-6"
            onClick={() => navigate(course.path)}
          >
            <div className="flex flex-col items-center">
              <div className="mb-4">
                {course.icon}
              </div>
              <h2 className="text-xl font-semibold text-center mb-4">
                {course.title}
              </h2>
              <p className="text-gray-600 text-center">
                {course.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;