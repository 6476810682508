import React from 'react';
import icon1 from './icons/icon1.png';
import icon2 from './icons/icon2.png';
import icon3 from './icons/icon3.png';
import icon4 from './icons/icon4.png';

const WhyChooseUs = () => {
  const features = [
    {
      title: "Innovative Solutions",
      description: "We deliver cutting-edge blockchain solutions tailored to your unique needs, ensuring efficiency, scalability, and security.",
      icon: icon1,
      leftAlign: true
    },
    {
      title: "End-to-End Support",
      description: "From concept to deployment and beyond, we provide comprehensive support, ensuring a seamless and transparent development journey.",
      icon: icon2,
      leftAlign: false
    },
    {
      title: "Commitment to Security",
      description: "Security is at the heart of everything we do. We utilize the latest encryption protocols and rigorous testing to protect your data and transactions.",
      icon: icon3,
      leftAlign: false
    },
    {
      title: "Transparency and Trust",
      description: "Blockchain is built on trust, and so are we. Our transparent processes and commitment to ethical practices set us apart.",
      icon: icon4,
      leftAlign: true
    }
  ];

  return (
    <div className="relative w-full max-w-6xl mx-auto py-16 px-4">
      {/* Mobile & Tablet View */}
      <div className="lg:hidden">
        <div className="flex flex-col items-center mb-8">
          {/* Top Circle */}
          <div className="bg-blue-200 rounded-full p-6 mb-4">
            <h2 className="text-lg font-bold text-center text-white">Why Choose Us</h2>
          </div>
          
          {/* Pink/Purple Section */}
          <div className="bg-gradient-to-br from-pink-100 via-purple-100 to-purple-200 rounded-3xl p-6 mb-8 w-full max-w-md">
            <p className="text-center text-gray-700 text-sm">
              Building trust through decentralized innovation, securing your future with blockchain solutions
            </p>
          </div>

          {/* Features List */}
          <div className="space-y-6 w-full max-w-md">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <img 
                  src={feature.icon} 
                  alt={feature.title}
                  className="w-12 h-12 mb-4"
                />
                <h3 className="font-bold mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Desktop View - Keeping Original Layout Exactly As Is */}
      <div className="hidden lg:block">
        <h2 className="text-3xl font-bold text-center mb-16">Why Choose Us</h2>

        <div className="flex justify-center items-center relative min-h-[500px]">
          {/* Left Circle */}
          <div className="absolute left-0 w-64 h-64 bg-gradient-to-br from-blue-200 to-blue-300 rounded-full flex items-center justify-center">
            <span className="text-white text-3xl font-bold text-center leading-tight">
              WHY<br />CHOOSE<br />US
            </span>
          </div>

          {/* Middle Blob */}
          <div className="absolute left-48 w-96 h-80 bg-gradient-to-br from-pink-100 via-purple-100 to-purple-200 rounded-[60%_40%_60%_40%] flex items-center">
            <p className="text-gray-700 font-medium max-w-[250px] ml-12">
              Building trust through decentralized innovation, securing your future with blockchain solutions
            </p>
          </div>

          {/* Feature List with Text and Icon Separately */}
          <div className="absolute right-0 space-y-[52px] pt-4">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className={`flex items-center w-[500px] ${feature.leftAlign ? 'ml-[-100px]' : ''}`}
              >
                {/* Icon */}
                <div className="mr-4">
                  <img 
                    src={feature.icon} 
                    alt={feature.title}
                    className="w-24 h-15 rounded-full"
                  />
                </div>

                {/* Horizontal Line */}
                <div 
                  className="bg-gray-300 mx-4" 
                  style={{ height: '1px', width: '50px' }}
                ></div>

                {/* Text */}
                <div className="flex flex-col">
                  <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-600 text-sm leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;