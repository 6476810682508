import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AboutCarousel = () => {
  const [currentImageSlide, setCurrentImageSlide] = useState(0);

  const stats = [
    {
      number: "05",
      label: "Years",
      gradient: "bg-gradient-to-br from-blue-300 to-blue-200"
    },
    {
      number: "50+",
      label: "Employees",
      gradient: "bg-gradient-to-br from-blue-400 to-blue-300"
    },
    {
      number: "3000+",
      label: "Global Network",
      gradient: "bg-gradient-to-br from-blue-500 to-blue-400"
    }
  ];

  const slides = [
    { title: "Integrity, Innovation and customer satisfaction" },
    { title: "Our team is a blend of expertise and creativity, driven to bring you the best" },
    { title: "Building lasting relationships through trust and service." },
    { title: "Driven by passion, powered by creativity." },
    { title: "Innovating the way our services work for you." }
  ];

  const images = [
    'assets/1.jpg',
    'assets/2.png',
    'assets/3.jpg',
    'assets/4.jpg',
    'assets/5.gif'
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [slides.length]);  

  useEffect(() => {
    const imageTimer = setInterval(() => {
      setCurrentImageSlide((prev) => (prev + 1) % images.length);
    }, 3000);
    return () => clearInterval(imageTimer);
  }, [images.length]);  

  return (
    <div className="w-full flex flex-col items-center bg-gray-50 text-gray-900 font-sans">
      <div className="w-full p-4 md:p-8">
        <motion.h1 
          className="text-3xl md:text-4xl font-extrabold text-center mb-4 tracking-wide"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          About Ocena
        </motion.h1>
        <motion.p 
          className="text-base md:text-lg text-center mb-8 max-w-4xl mx-auto px-4 leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Ocena is a pioneering technology solutions provider that empowers businesses to thrive in the digital era. <br/>
          As a trusted global partner, we specialize in delivering transformative AI-powered automation, blockchain integration, and cutting-edge digital solutions that revolutionize how enterprises operate.
          <p/>
          Our world-class expertise spans across industries, helping organizations unlock their full potential through sophisticated technological implementations. At Ocena, we combine innovation with practical business insights, creating customized solutions that drive efficiency, scalability, and sustainable growth.

        </motion.p>
      </div>

      <div className="w-full relative h-[300px] md:h-[500px] mb-8 md:mb-16">
        <div className="absolute inset-0 flex overflow-hidden">
          {images.map((img, index) => (
            <motion.div
              key={index}
              className="absolute w-full h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentImageSlide ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <div className="relative w-full h-full">
                <img
                  src={img}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/30" />
                <motion.div 
                  className="absolute inset-0 flex items-center justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <h2 className="text-2xl md:text-5xl font-extrabold text-center text-white">
                    {slides[index % slides.length].title}
                  </h2>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="w-full max-w-4xl flex flex-wrap justify-center mb-8 md:mb-16 gap-4 md:gap-16 px-4">
        {stats.map((stat, index) => (
          <motion.div
            key={index}
            className={`${stat.gradient} w-28 h-28 md:w-40 md:h-40 rounded-3xl shadow-lg p-2 md:p-4 flex flex-col items-center justify-center text-gray-900`}
            whileHover={{ scale: 1.1 }}
          >
            <span className="text-2xl md:text-4xl font-extrabold mb-1 md:mb-2">{stat.number}</span>
            <span className="text-base md:text-lg font-medium">{stat.label}</span>
          </motion.div>
        ))}
      </div>

      <motion.div 
        className="w-full max-w-4xl px-4 md:px-8 mb-8 md:mb-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-4 md:mb-8">Our Mission</h2>
        <p className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          At Ocena Smart Solutions, our mission is to revolutionize the way businesses operate by streamlining processes and delivering cutting-edge solutions. We harness the power of AI automation and Blockchain technology to create seamless, efficient, and scalable systems that drive growth and innovation.
        </p>
        <p className="text-base md:text-lg leading-relaxed">
          Beyond optimization, we are committed to building decentralized ecosystems that empower businesses with transparency, security, and absolute control over their operations. By bridging the gap between technology and business excellence, we pave the way for a future where enterprises thrive with smarter, faster, and more autonomous solutions.
        </p>
      </motion.div>
    </div>
  );
};

export default AboutCarousel;
