import React, { useState, useEffect, useCallback } from 'react';
import { Code2, BookOpen} from "lucide-react";



const EnrollmentForm = ({ isOpen, onClose }) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  
    useEffect(() => {
      if (!isOpen) return;
  
      // Remove any existing scripts first
      const existingScripts = document.querySelectorAll('script[data-payment_button_id]');
      existingScripts.forEach(script => script.remove());
  
      // Create and inject new script
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_Ps17XPW1aFbBpZ";
      
      script.onload = () => {
        setIsScriptLoaded(true);
      };
  
      script.onerror = (error) => {
        console.error("Failed to load Razorpay script:", error);
        setIsScriptLoaded(false);
      };
  
      // Get the form element and append script
      const form = document.getElementById("razorpay-payment-form");
      if (form) {
        form.appendChild(script);
      }
  
      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        setIsScriptLoaded(false);
      };
    }, [isOpen]);
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Enrollment Form</h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          <form id="razorpay-payment-form" className="min-h-[100px] display-flex justify-centre">
            {!isScriptLoaded && (
              <div className="flex items-center justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
};

const FrontendInfo = () => {
  const [showEnrollment, setShowEnrollment] = useState(false);
     const handleEnrollClick = useCallback(() => {
        setShowEnrollment(true);
      }, []);

    const handleDocsClick = useCallback(() => {
    const pdfUrl = "https://drive.google.com/file/d/1Od2rotzQQ1gSun9usG3MKotwo7Tga2Do/view?usp=drive_link"; // Replace with actual PDF URL
    window.open(pdfUrl, "_blank");
  }, []);
  
  return (
    <div className="max-w-5xl mx-auto p-8">
      <div className="bg-gradient-to-r from-white via-gray-50 to-gray-100 rounded-2xl shadow-2xl overflow-hidden">
        <div className="p-8">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-gray-800 mb-4">
              Frontend Development
            </h2>
            <p className="text-lg text-gray-600">
              Master modern web development technologies and frameworks to build stunning, responsive applications.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-6">
            <div className="p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-2xl font-semibold text-blue-600 mb-4">
                Core Technologies
              </h3>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>HTML5, CSS3, JavaScript (ES6+)</li>
                <li>React, Vue.js, Angular</li>
                <li>Responsive Design & Web Accessibility</li>
                <li>Modern CSS Frameworks (Tailwind, Bootstrap)</li>
              </ul>
            </div>

            <div className="p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-2xl font-semibold text-blue-600 mb-4">
                Additional Skills
              </h3>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>State Management (Redux, Context API)</li>
                <li>API Integration & RESTful Services</li>
                <li>Performance Optimization Techniques</li>
                <li>Testing & Debugging Best Practices</li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-6 mt-10">
            <button 
              className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white text-lg font-medium rounded-xl hover:bg-blue-700 transition-all duration-200 shadow-md hover:shadow-lg active:transform active:scale-[0.98]"
              onClick={() => handleEnrollClick(true)}
            >
              <Code2 size={24} />
              Pay Now
            </button>

            <button 
              className="flex items-center justify-center gap-2 px-6 py-3 border border-gray-300 text-lg font-medium rounded-xl hover:bg-gray-50 transition-all duration-200 shadow-md hover:shadow-lg active:transform active:scale-[0.98]"
              onClick={handleDocsClick}
            >
              <BookOpen size={24} />
              Get Curriculum
            </button>
          </div>
        </div>
      </div>
      
      <EnrollmentForm 
        isOpen={showEnrollment} 
        onClose={() => setShowEnrollment(false)} 
      />
    </div>
  );
};

export default FrontendInfo;
