import React from 'react';

import projectImage1 from './assets/Project1.png'; 
import projectImage2 from './assets/Project2.png'; 
import projectImage3 from './assets/Project3.png';    

import galleryImage1 from './assets/NFT2.png';
import galleryImage2 from './assets/Wallet.png';
import galleryImage3 from './assets/wazwan1.png';
import galleryImage4 from './assets/wazwan2.png';
import galleryImage5 from './assets/Project1.png';
import galleryImage6 from './assets/Project2.png';

import Icon1 from './assets/icons/clean_minimal.svg';
import Icon2 from './assets/icons/Responsive.svg';
import Icon3 from './assets/icons/Good Quality.svg';
import Icon4 from './assets/icons/reliable.svg';

const Portfolio = () => {
  const projects = [
    { img: projectImage1, title: "NFT Marketplace", desc: "Buy, sell, and trade unique digital collectibles and art seamlessly." },
    { img: projectImage2, title: "DeFi Application", desc: "A decentralized finance platform for lending, staking, and yield farming." },
    { img: projectImage3, title: "Full Stack Application", desc: "A full-stack application built using React, Node.js, and MongoDB." },
  ];

  const galleryImages = [galleryImage1, galleryImage2, galleryImage3, galleryImage4, galleryImage5, galleryImage6];
  const shuffledImages = [...galleryImages].sort(() => Math.random() - 0.5);
  
  // Responsive grid styles based on screen size
  const gridStyles = [
    "col-span-1 md:col-span-1",
    "col-span-1 md:col-span-2",
    "col-span-1 md:col-span-1",
    "col-span-1 md:col-span-1",
    "col-span-1 md:col-span-2",
    "col-span-1 md:col-span-1"
  ];

  return (
    <div className="container mx-auto px-4 py-6 md:py-10 max-w-7xl">
      {/* Header Section */}
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-4 md:mb-6">
        OCENA Portfolio
      </h1>

      {/* Features Section */}
      <div className="mb-8 md:mb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 md:gap-6">
          {[
            { icon: Icon1, text: "Clean & Minimal Design" },
            { icon: Icon2, text: "Responsive on All Devices" },
            { icon: Icon3, text: "High-Quality Design" },
            { icon: Icon4, text: "Reliable & Regular Updates" }
          ].map((feature, index) => (
            <div key={index} className="flex flex-col sm:flex-row items-center justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-3 p-4">
              <div className="w-14 h-14 md:w-16 md:h-16 flex justify-center items-center border-2 border-black rounded-full shrink-0">
                <img src={feature.icon} alt={feature.text} className="w-8 h-8 md:w-10 md:h-10" />
              </div>
              <span className="text-center sm:text-left text-base md:text-lg lg:text-xl">
                {feature.text}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Projects Section */}
      <h2 className="text-xl md:text-2xl font-semibold text-center mb-4 md:mb-6">
        Projects
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        {projects.map((project, index) => (
          <div 
            key={index} 
            className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img 
                src={project.img} 
                alt={project.title} 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <h3 className="font-semibold text-lg md:text-xl mb-2">
                {project.title}
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                {project.desc}
              </p>
              <div className="flex items-center mt-2 text-yellow-500">
                ★★★★☆
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Portfolio Gallery Section */}
      <h2 className="text-xl md:text-2xl font-semibold text-center mt-8 md:mt-12 mb-4 md:mb-6">
        Portfolio Gallery
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
        {shuffledImages.map((image, index) => (
          <div 
            key={index} 
            className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl ${gridStyles[index]}`}
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={image}
                alt={`Gallery Item ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
