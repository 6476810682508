import React, { useState } from 'react';
import { MapPin, Mail, Phone, User } from 'lucide-react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    subject: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');

    console.log("Submitting form data:", formData); // Debugging log

    try {
      const response = await axios.post(
        'https://ocena-backend2.onrender.com/contact-info-user',
        formData
      );

      console.log("API Response:", response); // Debugging log

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('✅ Thank you for reaching out! Our team will contact you soon.');

        // Reset form
        setFormData({
          name: '',
          phoneNumber: '',
          email: '',
          subject: '',
          message: ''
        });

        // Hide success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
          console.log("message",setSuccessMessage)
        }, 5000);
      }
    } catch (error) {
      console.error("Error sending message:", error.response ? error.response.data : error.message);
      alert(error.response?.data?.message || '❌ There was an error sending your message');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="text-center mb-12">
        <h2 className="text-2xl font-bold mb-2">CONTACT US</h2>
        <h1 className="text-4xl font-bold mb-16">Let's talk ! </h1>
      </div>

      {/* Success Message */}
      {successMessage && (
        <div className="mb-6 p-4 text-white bg-green-500 text-center rounded-lg">
          {successMessage}
        </div>
      )}

      {/* Contact Details */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-16">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <MapPin className="w-8 h-8 mb-4" />
          <h3 className="font-bold mb-2">Our Location</h3>
          <p className="text-gray-600">Mohali, Punjab India</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <Mail className="w-8 h-8 mb-4" />
          <h3 className="font-bold mb-2">Email Address</h3>
          <p className="text-gray-600">Hr@ocena.in</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <Phone className="w-8 h-8 mb-4" />
          <h3 className="font-bold mb-2">Phone Number</h3>
          <p className="text-gray-600">+91-7652992906</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <User className="w-8 h-8 mb-4" />
          <h3 className="font-bold mb-2">How Can We Help?</h3>
          <p className="text-gray-600">Tell us your problem we will get back to you ASAP.</p>
        </div>
      </div>

      {/* Form Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="w-full h-96 bg-blue-50 rounded-lg overflow-hidden shadow-lg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6856.777135828802!2d76.62135!3d30.763672!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ffbfdee9110d5%3A0xff40102bd5ab506b!2sOcena%20smart%20solutions!5e0!3m2!1sen!2sin!4v1738845548917!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg"
            title="Google Map of Mohali, Punjab"
          />
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6">Send us a Message</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block mb-2">Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                required
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block mb-2">Phone Number</label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
                required
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                required
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block mb-2">Subject</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
                required
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block mb-2">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message here..."
                rows={4}
                required
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
